var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-filter-wrapper',{attrs:{"type":"invoice","quick-filter-items":_vm.quickFilterItems,"additional-quick-filter-items":_vm.additionalQuickFilterItems,"search-disabled":_vm.searchDisabled},on:{"deleteFilter":_vm.onDeleteFilter,"deleteAdditionalFilter":_vm.onDeleteAdditionalFilter,"reset":_vm.resetSearchFilter,"search":_vm.doSearch,"focus":_vm.focus},scopedSlots:_vm._u([{key:"basic",fn:function(){return [_c('v-row',[(_vm.mayViewAllObjects)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('client-select',{attrs:{"for-view":"","show-inactive":"","for-accounting":"","nullable":""},model:{value:(_vm.filterParams.clientId),callback:function ($$v) {_vm.$set(_vm.filterParams, "clientId", $$v)},expression:"filterParams.clientId"}})],1):_vm._e(),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","sm":"6"}},[_c('date-picker-field',{attrs:{"clearable":"","as-utc":"","type":"month","allowed-dates":_vm.validFrom,"label":_vm.$t ('filter.date.from'),"error-messages":_vm.validationErrors(
            'filterParams.from',
            {
              beforeCurrentMonth: 'filter.error.futureDate'
            })},on:{"blur":function($event){_vm.$v.filterParams.$touch ()}},model:{value:(_vm.filterParams.from),callback:function ($$v) {_vm.$set(_vm.filterParams, "from", $$v)},expression:"filterParams.from"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","sm":"6"}},[_c('date-picker-field',{attrs:{"clearable":"","as-utc":"","type":"month","allowed-dates":_vm.validTo,"label":_vm.$t ('filter.date.to'),"error-messages":_vm.validationErrors(
            'filterParams.to',
            {
              beforeStart: 'filter.error.beforeStart',
              beforeCurrentMonth: 'filter.error.futureDate'
            })},on:{"blur":function($event){_vm.$v.filterParams.to.$touch ()}},model:{value:(_vm.filterParams.to),callback:function ($$v) {_vm.$set(_vm.filterParams, "to", $$v)},expression:"filterParams.to"}})],1)],1)]},proxy:true}]),model:{value:(_vm.filterVisibilityModel),callback:function ($$v) {_vm.filterVisibilityModel=$$v},expression:"filterVisibilityModel"}})}
var staticRenderFns = []

export { render, staticRenderFns }