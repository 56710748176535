import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters ({
      operationLink: 'request/operationLink'
    })
  },

  methods: {
    docUrl (id) {
      return this.operationLink ({
        op: 'account/get-all-invoice-docs',
        params: {
          id
        }
      })
    }
  }
}
