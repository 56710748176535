<messages>["@/app/i18n/common/GenericObject", "../Invoice"]</messages>

<template>
  <search-filter-wrapper
    v-model="filterVisibilityModel"
    type="invoice"
    :quick-filter-items="quickFilterItems"
    :additional-quick-filter-items="additionalQuickFilterItems"
    :search-disabled="searchDisabled"
    @deleteFilter="onDeleteFilter"
    @deleteAdditionalFilter="onDeleteAdditionalFilter"
    @reset="resetSearchFilter"
    @search="doSearch"
    @focus="focus">
    <!-- filter form -->

    <template #basic>
      <v-row>
        <!-- should be replaced with viewall/viewsubclient if GW supports invoices for reseller-->
        <v-col v-if="mayViewAllObjects" class="pa-1" cols="12">
          <client-select
            v-model="filterParams.clientId"
            for-view
            show-inactive
            for-accounting
            nullable/>
        </v-col>
        <v-col class="pa-1" cols="12" sm="6">
          <date-picker-field
            v-model="filterParams.from"
            clearable
            as-utc
            type="month"
            :allowed-dates="validFrom"
            :label="$t ('filter.date.from')"
            :error-messages="validationErrors(
              'filterParams.from',
              {
                beforeCurrentMonth: 'filter.error.futureDate'
              })"
            @blur="$v.filterParams.$touch ()"/>
        </v-col>
        <v-col class="pa-1" cols="12" sm="6">
          <date-picker-field
            v-model="filterParams.to"
            clearable
            as-utc
            type="month"
            :allowed-dates="validTo"
            :label="$t ('filter.date.to')"
            :error-messages="validationErrors(
              'filterParams.to',
              {
                beforeStart: 'filter.error.beforeStart',
                beforeCurrentMonth: 'filter.error.futureDate'
              })"
            @blur="$v.filterParams.to.$touch ()"/>
        </v-col>
      </v-row>
    </template>
  </search-filter-wrapper>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  import SearchFilterWrapper
    from '@/app/core/components/Search/SearchFilterWrapper'

  import DatePickerField, {beforeCurrentMonth}
    from '@/app/core/components/DatePickerField'

  import ClientSelect from '@/app/core/components/ClientSelect'

  import InputHelper from '@/app/core/mixins/InputHelper'
  import QuickFilterMixin from '@/app/core/mixins/QuickFilterMixin'
  import SearchFilterMixinCreator from '@/app/core/mixins/SearchFilterCreator'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  /**
   * default invoice search filter parameters
   * @type {Object}
   */
  export const defaultValue = () => ({
    clientId: null,
    from: '',
    to: ''
  })

  export default {
    name: 'InvoiceFilter',

    components: {
      SearchFilterWrapper,
      DatePickerField,
      ClientSelect
    },

    mixins: [
      SearchFilterMixinCreator (defaultValue),
      QuickFilterMixin,
      InputHelper,
      validationMixins
    ],

    data () {
      return {
        customHandlerKeys: [
          'from', 'to'
        ]
      }
    },

    validations () {
      return {
        filterParams: {
          to: {
            beforeStart (value) {
              return this.filterParams.from && value
                ? new Date (this.filterParams.from) <= new Date (value)
                : true
            },
            beforeCurrentMonth
          },
          from: {
            beforeCurrentMonth
          }
        }
      }
    },

    computed: {
      ...mapGetters ({
        hasSubClients: 'auth/hasSubClients',
        mayViewAllObjects: 'auth/mayViewAllObjects'
      })
    },

    methods: {
      ...mapActions ({
        getClientName: 'cache/getClientName'
      }),

      validFrom (dateStr) {
        if (!this.filterParams.to) {
          return beforeCurrentMonth (dateStr)
        }

        const d = new Date (dateStr)
        const to = new Date (this.filterParams.to)

        return beforeCurrentMonth (dateStr) && d <= to
      },

      validTo (dateStr) {
        if (!this.filterParams.from) {
          return beforeCurrentMonth (dateStr)
        }

        const d = new Date (dateStr)
        const from = new Date (this.filterParams.from)

        return beforeCurrentMonth (dateStr) && d >= from
      },

      customQuickFilterHandler (prop, value, qFItems) {
        let text = value
        let label = prop

        switch (prop) {
          case 'from':
          case 'to':
            text = this.formatDateMonth (value)
            label = this.$t (`label.${prop}`)
            break
        }

        qFItems.push ({
          prop,
          label,
          text
        })
      }
    }
  }
</script>
