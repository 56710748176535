<messages>["@/app/i18n/common/GenericObject", "./Invoice"]</messages>

<template>
  <base-layout :mw="$vuetify.breakpoint.lgAndDown ? '2' : '3'">
    <!-- filters -->
    <v-col cols="12" lg="8" xl="4">
      <!-- search filter -->
      <invoice-filter
        :value="filter || undefined"
        :visible="showFilter"
        :search-disabled="isLoading"
        @input="onFilterChange"
        @visibilityChanged="onFilterVisibilityChange"/>
    </v-col>

    <!-- invoice list -->
    <v-col cols="12" xl="8">
      <v-alert
        v-model="showResultAlert"
        v-t="'general.info.searchResults'"
        type="info"
        color="primary"/>

      <v-card v-if="isResultTableVisible">
        <v-card-title primary-title>
          <div
            v-t="'list.title'"
            class="text-h5"/>
          <v-spacer/>
          <csv-download-btn :total-count="totalCount" :csv-download-url="csvDownloadUrl"/>
        </v-card-title>
        <v-card-text>
          <invoice-table
            v-bind="paginationState"
            :invoice-data="resultList"
            :loading="isLoading"
            :total-count="totalCount"
            :rows-per-page-items="rowsPerPageItems"
            @revoke="onRevoke"
            @create="onCreate"
            @publish="onPublish"
            @reset="onReset"
            @paginationStateChanged="({newValue, oldValue}) =>
              onPaginationStateChanged (newValue, oldValue)"/>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import InvoiceFilter from './components/InvoiceFilter'
  import InvoiceTable from './components/InvoiceTable'

  import SearchPage from '@/app/core/mixins/SearchPage'
  import CsvDownloadBtn from '@/app/core/components/CsvDownloadBtn'

  export default {
    name: 'InvoiceSearch',

    components: {
      BaseLayout,
      InvoiceFilter,
      InvoiceTable,
      CsvDownloadBtn
    },

    mixins: [SearchPage],

    data () {
      return {
        // this value is used by mixin for sending requests to the BE API
        OPERATION: 'account/list-invoices'
      }
    },

    computed: {
      ...mapState ('filter', [
        'invoiceSearchQuery'
      ])
    },

    methods: {
      // --- mutation and actions, mapped from vuex ----------------------------
      ...mapMutations ({
        storeSearchQuery: 'filter/setInvoiceSearchQuery',
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * implementation of getting the stored search filter, which will be used
       * on component creation
       */
      getStoredSearchQuery () {
        return this.invoiceSearchQuery
      },

      /**
       * send the "revoke invoice" command to the BE
       *
       * @param id          ID of the invoice to be revoked
       */
      onRevoke (id) {
        this.fetchData ({
          op: 'account/revoke-invoice',
          params: {
            id
          },
          cb: () => {
            this.load ()
          }
        })
      },

      onReset (id) {
        this.fetchData ({
          op: 'account/reset-invoice',
          params: {
            id
          },
          cb: () => {
            this.displaySuccessMessage (this.$t ('view.resetSuccess'))
          }
        })
      },

      /**
       * send the "create invoice" command to the BE
       *
       * @param id          ID of the invoice to be re-created
       */
      onCreate (id) {
        this.fetchData ({
          op: 'account/create-invoice',
          params: {
            id
          },
          cb: () => {
            this.load ()
          }
        })
      },

      /**
       * send the "publish invoice" command to the BE
       *
       * @param id          ID of the invoice to be published
       */
      onPublish (id) {
        this.fetchData ({
          op: 'account/publish-invoice',
          params: {
            id
          },
          cb: () => {
            this.load ()
          }
        })
      }
    }
  }
</script>
