<messages>["@/app/i18n/common/GenericObject", "../Invoice"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-data-table
    class="elevation-1"
    :headers="headers"
    :items="invoiceData"
    :page.sync="pagination.page"
    :items-per-page.sync="pagination.rowsPerPage"
    :sort-by.sync="pagination.sortBy"
    :sort-desc.sync="pagination.descending"
    :footer-props="{...footerProps, 'items-per-page-options': rowsPerPageItems}"
    :server-items-length="totalCount"
    :loading="loading">
    <template #item="props">
      <tr>
        <td>
          <router-link
            :to="{
              name: 'accounting.invoice.view',
              params: {id: props.item.id }
            }">
            {{ props.item.invoiceNumber }}
          </router-link>
        </td>
        <td v-t="`state.${props.item.state}`"/>
        <td v-text="formatDateShort (props.item.creationDate)"/>
        <td v-if="isClientVisible">
          <client-link :id="props.item.clientId"/>
        </td>
        <td v-text="periodText (props.item)"/>
        <td>
          <action-buttons
            :value="isActionButtonsActive (props.item.invoiceNumber)"
            :buttons="getActionButtons (props.item)"
            @input="state => setActionButtonsActive (props.item.invoiceNumber) (state)"
            @clicked="processActionButton"/>
        </td>
      </tr>
    </template>
    <template #no-data>
      <search-table-no-data
        :is-loading="loading"
        :text="$t ('list.empty')"/>
    </template>
  </v-data-table>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import registryCommands from '@/app/core/mixins/RegistryCommands'
  import ActionButtons from '@/app/core/components/ActionButtons'
  import ClientLink from '@/app/core/components/ClientLink'
  import SearchTableNoData from '@/app/core/components/Search/SearchTableNoData'
  import InvoiceDownloadHelper from './InvoiceDownloadHelper'

  import {mapGetters} from 'vuex'

  export default {
    name: 'BookEntryTable',

    components: {
      ActionButtons,
      ClientLink,
      SearchTableNoData
    },

    mixins: [
      paginationMixins,
      actionButtonsHelper,
      registryCommands,
      InvoiceDownloadHelper
    ],

    props: {
      totalCount: {
        type: Number,
        default: 0
      },
      invoiceData: {
        type: Array,
        default: () => []
      },
      rowsPerPageItems: {
        type: Array,
        required: true
      },
      loading: Boolean
    },

    computed: {
      ...mapGetters ('auth', [
        'hasAllOfPermissions',
        'mayViewOnlyOwnObjects'
      ]),

      isAdmin () {
        return this.hasAllOfPermissions (['ViewAllObjects'])
      },

      isClientVisible () {
        return !this.mayViewOnlyOwnObjects
      },

      headers () {
        const tvs = (text, value = undefined, sortable = false) => ({
          text,
          value,
          sortable
        })
        const headerItem = it => tvs (this.$t (`label.${it}`), it)

        return [
          headerItem ('invoiceNumber'),
          headerItem ('state'),
          headerItem ('creationDate'),
          headerItem ('clientId'),
          tvs (`${this.$t ('label.from')} - ${this.$t ('label.to')}`),
          tvs (this.$t ('general.label.actions'))
        ]
      }
    },

    methods: {
      /**
       * get the invoice period as text for specified item
       *
       * @param {Object} item     the invoice object
       */
      periodText ({from, to}) {
        const f = this.formatDateShort

        return `${f (from)} - ${f (to)}`
      },

      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        return [
          {
            action: 'view',
            itemId: item.id,
            icon: 'visibility',
            tooltip: this.$t ('general.button.view')
          },
          {
            href: this.docUrl (item.id),
            icon: 'cloud_download',
            tooltip: this.$t ('general.button.download')
          },
          ...(
            this.isAdmin && item.state === 'Public'
              ? [
                {
                  action: 'revoke',
                  itemId: item.id,
                  icon: 'undo',
                  tooltip: this.$t ('general.button.revoke')
                }]
              : []
          ),
          ...(
            this.canCreateForInvoice (item)
              ? [
                {
                  action: 'create',
                  itemId: item.id,
                  icon: 'replay',
                  tooltip: this.$t (
                    'general.button.regenerate')
                }]
              : []
          ),
          ...(
            this.isAdmin && item.state === 'InternalReview'
              ? [
                {
                  action: 'publish',
                  itemId: item.id,
                  icon: 'public',
                  tooltip: this.$t ('general.button.publish')
                }]
              : []
          ),
          ...(
            this.isAdmin && item.state === 'Failed'
              ? [
                {
                  action: 'reset',
                  itemId: item.id,
                  icon: 'refresh',
                  tooltip: this.$t ('general.button.reset')
                }]
              : []
          )
        ]
      },

      /**
       * is it possible to re-create given invoice?
       *
       * @param {Object}      invoice object
       */
      canCreateForInvoice ({state, clientId, from, to}) {
        return this.isAdmin && (
          // if the invoice is in the "revoked" state and no "pending", "public"
          // or "internal review" invoice for the same period exists
          (state === 'Revoked' && !this.invoiceData.some (it =>
            it.from === from && it.to === to &&
            it.clientId === clientId && it.state !== 'Revoked')) ||
          // if the invoice is in the "internal review" state and no "pending"
          // or "public" invoice for the same period exists
          (state === 'InternalReview' && !this.invoiceData.some (it =>
            it.from === from && it.to === to &&
            it.clientId === clientId &&
            !['InternalReview', 'Revoked'].includes (it.state)))
        )
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        switch (button.action) {
          case 'view':
            this.$router.push ({
              name: 'accounting.invoice.view',
              params: {id: button.itemId}
            })
            break

          case 'revoke':
            this.$emit ('revoke', button.itemId)
            break

          case 'create':
            this.$emit ('create', button.itemId)
            break

          case 'publish':
            this.$emit ('publish', button.itemId)
            break
          case 'reset':
            this.$emit ('reset', button.itemId)
            break

          default:
            console.error (
              `"processActionButton": the action '${button.action}' is unknown`)
            break
        }
      },

      /**
       * handle pagination state changes
       *
       * @param newValue      new pagination state
       * @param oldValue      old pagination state
       */
      onPaginationStateChanged (newValue, oldValue) {
        this.$emit ('paginationStateChanged', {newValue, oldValue})
      }
    }
  }
</script>>
